<template>
    <div class="first-step-component" v-loading="[checkFormLoading, true]">
        <a-form
            ref="formRef"
            name="custom-validation"
            class="frist-step-form"
            :model="formState"
            :rules="rules"
            v-bind="layout"
            :label-col="labelCol"
        >
            <a-form-item label="项目名称" name="projectName">
                <a-input
                    v-model:value="formState.projectName"
                    placeholder="请输入您的项目名称"
                    autocomplete="off"
                    :maxlength="30"
                    show-count
                />
            </a-form-item>
            <a-form-item label="项目总规划装机容量" name="totalProjectCapacity">
                <a-input
                    autocomplete="off"
                    placeholder="请输入总装机容量"
                    suffix="MW"
                    v-model:value="formState.totalProjectCapacity"
                />
            </a-form-item>
            <a-form-item label="本期规划装机容量" name="currentProjectCapacity">
                <a-input
                    autocomplete="off"
                    placeholder="请输入本期装机容量"
                    suffix="MW"
                    v-model:value="formState.currentProjectCapacity"
                    @blur="sessionSaveCapacity"
                />
            </a-form-item>
            <a-form-item label="储能系统" name="energyStorage">
                <a-radio-group v-model:value="formState.energyStorage" :options="storageOptions" />
            </a-form-item>
            <div
                class="left-indent"
                style="margin-bottom: 10px"
                v-if="formState.energyStorage === 2"
            >
                <a-form-item class="auto-form-item" label="租借单价" name="rentalUnitPrice">
                    <a-row type="flex" align="middle">
                        <a-input-number
                            v-model:value="formState.rentalUnitPrice"
                            :controls="false"
                        ></a-input-number>
                        <span style="margin-left: 12px">万元 MWH/年</span>
                    </a-row>
                </a-form-item>
            </div>
            <div
                class="left-indent"
                style="margin-bottom: 10px"
                v-if="formState.energyStorage === 2"
            >
                <a-form-item class="auto-form-item" label="费用支付方式" name="rentalPayType">
                    <a-select
                        class="special-select"
                        v-model:value="formState.rentalPayType"
                        placeholder=""
                    >
                        <a-select-option v-for="item in payTypeOptions" :value="item['value']">{{
                            item['label']
                        }}</a-select-option>
                    </a-select>
                </a-form-item>
            </div>
            <div
                class="left-indent"
                style="margin-bottom: 0"
                v-if="formState.energyStorage == 1 || formState.energyStorage == 2"
            >
                <a-form-item class="auto-form-item" label="配置容量占本期比例" name="storageRatio">
                    <a-select
                        class="special-select"
                        v-model:value="formState.select"
                        @change="onChangeSelectStorageRatio"
                        placeholder=""
                    >
                        <a-select-option
                            v-for="item in selectStorageRatio"
                            :value="item['value']"
                            >{{ item['label'] }}</a-select-option
                        >
                    </a-select>
                </a-form-item>
            </div>
            <a-form-item label="场区坐标" name="pointType">
                <a-radio-group v-model:value="formState.pointType" :options="pointOptions" />
            </a-form-item>
            <fieldPoint
                ref="fieldPointRef"
                :pointType="formState.pointType"
                @checkFormValidateFields="checkFormValidateFields"
            ></fieldPoint>
            <div class="line-break"></div>
            <!-- 高级参数设置 -->
            <div v-show="showMoreKey">
                <a-form-item label="倾向使用的风机类型" name="fanClassify" class="self-input-width">
                    <fanClassify @checkValue="validateFanClassify()" ref="fanClassifyRef" />
                </a-form-item>
                <a-form-item class="auto-form-item" label="倾向轮毂高度" name="preferHubHeight">
                    <a-select
                        class="special-select"
                        v-model:value="formState.preferHubHeight"
                        placeholder=""
                    >
                        <a-select-option
                            v-for="item in preferHubHeightOptions"
                            :value="item['value']"
                            >{{ item['label'] }}</a-select-option
                        >
                    </a-select>
                </a-form-item>
                <a-form-item label="风机点位" name="fanLocation">
                    <a-radio-group
                        v-model:value="formState.fanLocation"
                        :options="fanLocationOptions"
                    />
                </a-form-item>
                <!-- 批量导入逻辑 -->
                <fanPoint
                    ref="fanPointRef"
                    v-if="formState.fanLocation"
                    :pointType="formState.pointType"
                    :handleDisabled="handleDisabled"
                    @checkFormValidateFields="checkFormValidateFieldsFan"
                    @clearValidateFan="clearValidateFan"
                ></fanPoint>
                <a-form-item label="升压站点位" name="boosterLocation">
                    <a-radio-group
                        :class="formState.boosterLocation == 1 ? 'position-margin' : ''"
                        v-model:value="formState.boosterLocation"
                        :options="fanLocationOptions"
                    />
                    <div class="handle-point-component" v-if="formState.boosterLocation == 1">
                        <p class="handle-point-content">
                            <span class="handle-point-control-other">
                                <i
                                    :class="handlePointCount == 1 ? 'active' : ''"
                                    @click="changePointCount(1)"
                                    >1</i
                                >
                                <i
                                    :class="handlePointCount == 2 ? 'active' : ''"
                                    @click="changePointCount(2)"
                                    >2</i
                                >
                            </span>
                            <span>个升压站点位</span>
                        </p>
                        <a-flex
                            justify="flex-start"
                            class="booster-list"
                            v-for="(item, itemIndex) in formState.boosterStationLocations"
                        >
                            <a-form-item
                                :rules="[
                                    {
                                        required: true,
                                        trigger: 'blur',
                                        validator: validateCheckLng,
                                    },
                                ]"
                                has-feedback
                                label=""
                                :name="['boosterStationLocations', itemIndex, 'lng']"
                                class="booster-list-item"
                            >
                                <a-input-number
                                    :step="0.000001"
                                    :controls="false"
                                    placeholder="请输入经度坐标"
                                    v-model:value="item.lng"
                                    autocomplete="off"
                                    @blur="checkStationLocations"
                                />
                            </a-form-item>
                            <a-form-item
                                :rules="[
                                    {
                                        required: true,
                                        trigger: 'blur',
                                        validator: validateCheckLat,
                                    },
                                ]"
                                has-feedback
                                label=""
                                :name="['boosterStationLocations', itemIndex, 'lat']"
                                class="booster-list-item"
                            >
                                <a-input-number
                                    :step="0.000001"
                                    :controls="false"
                                    placeholder="请输入纬度坐标"
                                    v-model:value="item.lat"
                                    autocomplete="off"
                                    @blur="checkStationLocations"
                                />
                            </a-form-item>
                            <a-form-item
                                :rules="[{ required: true, validator: validateCheckBestIsSelect }]"
                                has-feedback
                                label=""
                                :name="['boosterStationLocations', itemIndex, 'isBest']"
                                class="booster-list-item"
                            >
                                <a-select
                                    class="booster-select"
                                    v-model:value="item.isBest"
                                    placeholder="是否最优"
                                >
                                    <a-select-option
                                        v-for="item in selectbestRatio"
                                        :value="item['value']"
                                        >{{ item['label'] }}</a-select-option
                                    >
                                </a-select>
                            </a-form-item>
                        </a-flex>
                    </div>
                </a-form-item>
                <a-form-item label="地形现场照片">
                    <uploadImg ref="uploadImgLandformRef" :obsFilePath="obsFilePath"></uploadImg>
                    <p class="form-item-illustrate">
                        非必填，支持 JPG、PNG格式，上限2个，单文件最大5M。用于报告中展示
                    </p>
                </a-form-item>
            </div>
            <div class="show-more" v-show="!showMoreKey" @click="showMoreKey = true">
                <span>高级参数设置</span>
                <iconpark-icon :size="10" name="shuangjiantoushouqi"></iconpark-icon>
                <div class="show-more-illustrate">
                    <i>!</i>
                    <span>点击这里可进行更详细数据参数设置</span>
                </div>
            </div>
            <div class="retract-more" v-show="showMoreKey" @click="showMoreKey = false">
                <span>收起</span>
                <iconpark-icon
                    :size="10"
                    class="retract-icon"
                    name="shuangjiantoushouqi"
                ></iconpark-icon>
            </div>
            <div class="handle-submit-group">
                <a-button
                    v-softDog="{
                        callback: onSubmitForm,
                        params: 1,
                        packageType: PACKAGE_TYPE.default,
                    }"
                    v-if="route.query.type !== 'edit'"
                    >保存草稿</a-button
                >
                <a-button
                    v-softDog="{
                        callback: onSubmitForm,
                        params: 2,
                        packageType: PACKAGE_TYPE.default,
                    }"
                    class="step-next"
                    >下一步</a-button
                >
            </div>
        </a-form>
    </div>
</template>
<script setup>
    import dayjs from 'dayjs';
    import { useDebounceFn } from '@vueuse/core';
    import { reactive, ref, onMounted, watch, h, nextTick, onBeforeMount } from 'vue';
    import { useRoute } from 'vue-router';
    import fanClassify from '../../components/fanClassify/fanClassify.vue';
    import uploadImg from '../../components/uploadImg/uploadImg.vue';
    import { message, Modal } from 'ant-design-vue';
    import eventBus from '@/utils/eventBus';
    import {
        uniqueIdentification, // 生成唯一标识
        saveFormData, // 保存更新提交表单（新增）
        updateFormData, // 调整保存或者更新表单（调整）
        selectProjectInfoById, //根据项目ID进行信息回显
    } from '@/api/modules/firstStep';
    import { PACKAGE_TYPE } from '@/assets/js/static.js';
    import { coverPoints, getDictionary } from '@/api/modules/base.js';
    import fieldPoint from '../../components/fieldPoint/fieldPoint.vue';
    import fanPoint from '../../components/fanPoint/fanPoint.vue';
    import usefieldFanDataStore from '@/pinia/modules/fieldFanData.js';
    import { useObsUpload } from '@/components/obsClient/obsUpload.js';
    import shp from '@/assets/js/shp.esm.js';
    import { obsClientInfo } from '@/assets/js/static.js';
    import { checkPointInArea } from '@/utils/checkPointInArea.js';
    const obsClient = useObsUpload();
    const fieldFanDataStore = usefieldFanDataStore();
    const fieldPointRef = ref(null);
    const fanPointRef = ref(null);
    const route = useRoute();
    // 表单REF
    const formRef = ref(); // 整体表单
    const handlePointRef = ref(null); // 手动填写场区坐标点组件
    const fanClassifyRef = ref(null); // 风机类型填写组件
    const uploadImgLandformRef = ref(null); // 上传地形图组件
    const labelCol = { style: { width: '270px', marginRight: '16px' } };
    // 表单数据变量
    let formState = ref({
        step: 1, // step第一步
        projectName: '',
        totalProjectCapacity: '', // 总装机容量
        currentProjectCapacity: '', // 本期装机容量
        energyStorage: 3, // 储能系统
        storageRatio: null, // 配置容量占本期比例
        rentalUnitPrice: '', // 配置容量租借单价
        pointType: 0, // 场区坐标类型 0批量导入 1手动录入
        fanLocation: 0, // 风机点位
        boosterLocation: 0, // 升压站点位
        boosterStationLocations: [{}],
        preferHubHeight: 120, // 倾向轮毂高度
        rentalPayType: null, // 费用支付方式
    });
    // 倾向轮毂高度数据
    const preferHubHeightOptions = ref([
        {
            label: '100m',
            value: 100,
        },
        {
            label: '105m',
            value: 105,
        },
        {
            label: '110m',
            value: 110,
        },
        {
            label: '120m',
            value: 120,
        },
        {
            label: '130m',
            value: 130,
        },
        {
            label: '140m',
            value: 140,
        },
    ]);
    // 验证表单的loading
    const checkFormLoading = ref(false);
    // obs文件存储路径变量
    const obsFilePath = ref('');
    // 生成项目专属随机码
    const getProjectRandom = () => {
        return dayjs(new Date()).format('YYYYMMDDHHmmss').toString() + createRandom(6);
    };
    const createRandom = (n) => {
        return parseInt(Math.random() * Math.pow(10, n));
    };
    // 用户输入本期容量先临时存储
    const sessionSaveCapacity = async () => {
        if (!formState.value.currentProjectCapacity) {
            return false;
        }
        sessionStorage.setItem('currentProjectCapacity', formState.value.currentProjectCapacity);
        console.log(fieldFanDataStore.pointData);
        // 手动录入一个坐标情况下，生成圆的半径与本期规划容量大小有关联
        if (fieldFanDataStore.pointData[0].length == 1 && formState.value.pointType == 1) {
            console.log(fieldFanDataStore.pointData);
            const originData = JSON.parse(JSON.stringify(fieldFanDataStore.pointData));
            let formatData = [];
            const resultData = [];
            fieldFanDataStore.pointData = []; 
            const res = await formatLatLng(originData, 'lng', 'lat');
            formatData = res;
            formatData.length &&
                formatData.map((ele) => {
                    resultData.push([ele.lat * 1, ele.lng * 1]);
                    fieldFanDataStore.pointData.push({
                        regionCode: 1,
                        longitude: ele.lng,
                        latitude: ele.lat,
                    });
                });
            console.log(resultData, 'resultData');
            eventBus.emit('uploadPoint', [resultData]);
            eventBus.emit('fanDisabled', false);
        }
    };
    onBeforeMount(() => {
        getPayDictionary();
    });
    // 客服信息
    const customInfos = ref();
    onMounted(() => {
        if (sessionStorage.getItem('customerInfo')) {
            customInfos.value = JSON.parse(sessionStorage.getItem('customerInfo'));
        }
        if (!!route.query.type) {
            // 项目回显
            getProjectInfoByID(sessionStorage.getItem('projectID'));
        } else {
            // 新增项目创建一个路径地址
            obsFilePath.value =
                sessionStorage.getItem('userName') +
                '/fd/pro-' +
                createRandom(6) +
                '/' +
                getProjectRandom() +
                '/od/';
            sessionStorage.setItem('obsFilePath', obsFilePath.value);
            // 新增情况下，直接生成一个新的唯一标识
            fieldFanDataStore.uniqueIdentificationUUID = uniqueIdentification();
        }
    });
    // 验证反馈
    let validateName = async (_rult, value) => {
        if (!value) {
            return Promise.reject('请输入您的项目名称');
        } else {
            if (value.length < 2) {
                return Promise.reject('项目名称最少两个字符');
            } else {
                return Promise.resolve();
            }
        }
    };
    let validateTotalCapacity = async (_rult, value) => {
        const formateValue = Number(value);
        if (!value || isNaN(formateValue)) {
            formState.value.totalProjectCapacity = '';
            return Promise.reject('请输入总装机容量');
        } else {
            if (formateValue < 50 || formateValue > 1000) {
                return Promise.reject('总装机容量范围为50~1000,可输入2位小数点');
            } else {
                if (
                    formState.value.currentProjectCapacity &&
                    (formState.value.currentProjectCapacity < formateValue ||
                        formState.value.currentProjectCapacity > formateValue)
                ) {
                    formRef.value.validateFields(['currentProjectCapacity']);
                }
                formState.value.totalProjectCapacity = formateValue.toFixed(2);
                return Promise.resolve();
            }
        }
    };
    let validateCurrentCapacity = async (_rult, value) => {
        const formateValue = Number(value);
        if (!value || isNaN(formateValue)) {
            formState.value.currentProjectCapacity = '';
            return Promise.reject('请输入本期装机容量');
        } else {
            if (formateValue < 50 || formateValue > 200) {
                return Promise.reject('本期装机容量范围为50~200,若您有需要可联系平台客服处理');
            } else {
                if (formState.value.totalProjectCapacity < formateValue) {
                    return Promise.reject('本期装机容量不能大于总装机容量');
                }
                formState.value.currentProjectCapacity = formateValue.toFixed(2);
                return Promise.resolve();
            }
        }
    };
    let validateStorageRatio = async (_rule, value) => {
        if (formState.value.energyStorage !== 3 && formState.value.select == null) {
            return Promise.reject('请选择配置容量占本期比例');
        } else {
            return Promise.resolve();
        }
    };
    let validateStoragePrice = async (_rule, value) => {
        if (formState.value.energyStorage == 2 && !formState.value.rentalUnitPrice) {
            return Promise.reject('请输入租借单价');
        } else {
            return Promise.resolve();
        }
    };
    let validateCheckBestIsSelect = async (_rule, value) => {
        // 检查formState.boosterStationLocations中best为1的，返回数组
        const bestValue = formState.value.boosterStationLocations.filter(
            (item) => item.isBest === 1,
        );
        if (!value && value !== 0) {
            return Promise.reject('请选择是否最优');
        } else {
            // 检查
            if (bestValue.length > 1) {
                return Promise.reject('最优只能选择一个');
            } else {
                return Promise.resolve();
            }
        }
    };
    let validateFanClassify = async (_rule, value) => {
        formRef.value.clearValidate(['fanClassify']);
        // 检查风机类型
        if (fanClassifyRef.value.existType * 1 && !fanClassifyRef.value.getData().length) {
            return Promise.reject('请至少输入一组有效的风机类型信息');
        } else {
            let errorKey = false;
            const data = fanClassifyRef.value.getData();
            let messgFlag = data.some((m) => m.errorMsg);
            if (messgFlag) {
                return Promise.reject('');
            }
            data.map((ele) => {
                const filterResult = data.filter(
                    (item) => item.classify === ele.classify && item.power === ele.power,
                );
                if (filterResult.length > 1) {
                    errorKey = true;
                }
            });
            if (errorKey) {
                return Promise.reject('风机类型信息中存在重复项');
            } else {
                let resultStr = '';
                data.map((ele, eleIndex) => {
                    resultStr += ele.classify + ',' + ele.power;
                    if (eleIndex !== data.length - 1) {
                        resultStr += ';';
                    }
                });
                formState.value.preferDeviceCodes = resultStr;
                return Promise.resolve();
            }
        }
    };
    // 风机点位文件验证
    let validateFanLocation = async (_rule, value) => {
        if (formState.value.fanLocation) {
            console.log(fieldFanDataStore);
            if (fieldFanDataStore.fanFile && fieldFanDataStore.fanFile.length > 0) {
                if (fanPointRef.value.fanPointFileInfo[0]['filePath']) {
                    return Promise.resolve();
                } else {
                    return Promise.reject('风机点位文件上传异常，请删除后重新上传！');
                }
            } else {
                return Promise.reject('请上传风机点位文件！');
            }
        } else {
            return Promise.resolve();
        }
    };
    // 场区坐标文件验证
    let validatePointType = async (_rule, value) => {
        if (formState.value.pointType == 0) {
            if (fieldFanDataStore.pointFile && fieldFanDataStore.pointFile.length > 0) {
                if (fieldPointRef.value.pointFileInfo.length && fieldPointRef.value.pointFileInfo[0]['filePath']) {
                    return Promise.resolve();
                } else {
                    return Promise.reject('场区坐标文件上传异常，请删除后重新上传！');
                }
            } else {
                return Promise.reject('请上传场区坐标文件！');
            }
        } else {
            if (
                fieldPointRef.value.handlePointRef.getData() &&
                fieldPointRef.value.handlePointRef.getData().length > 0
            ) {
                return Promise.resolve();
            } else {
                // return Promise.reject('请录入场区拐点坐标！');
                return Promise.reject('');
            }
        }
    };
    // 验证经度坐标
    let validateCheckLng = async (_rule, value) => {
        console.log(value);
        if (value) {
            if (
                (value <= 135.05 && value >= 73.33) ||
                (value <= 45503314.048 && value >= 20727653.648)
            ) {
                return Promise.resolve();
            } else {
                return Promise.reject('经度坐标格式错误，请重新填写！');
            }
        } else {
            return Promise.reject('请录入升压站经度坐标！');
        }
    };
    // 验证纬度坐标
    let validateCheckLat = async (_rule, value) => {
        if (value) {
            if ((value <= 53.33 && value >= 3.51) || (value <= 5935831.83 && value >= 453351.689)) {
                return Promise.resolve();
            } else {
                return Promise.reject('纬度坐标格式错误，请重新填写！');
            }
        } else {
            return Promise.reject('请录入升压站纬度坐标！');
        }
    };
    const rules = reactive({
        projectName: [
            {
                required: true,
                validator: validateName,
                trigger: 'blur',
            },
        ],
        totalProjectCapacity: [
            {
                required: true,
                validator: validateTotalCapacity,
                trigger: 'blur',
            },
        ],
        // 本期规划装机容量
        currentProjectCapacity: [
            {
                required: true,
                validator: validateCurrentCapacity,
                trigger: 'blur',
            },
        ],
        // 储能
        energyStorage: [
            {
                required: true,
            },
        ],
        // 场区坐标导入类型
        pointType: [
            {
                required: true,
                validator: validatePointType,
                trigger: 'change',
            },
        ],
        // 风机类型
        fanClassify: [
            {
                required: false,
                validator: validateFanClassify,
                trigger: 'change',
            },
        ],
        // 风机点位
        fanLocation: [
            {
                required: true,
                validator: validateFanLocation,
                trigger: 'change',
            },
        ],
        // 配置容量占本期比例
        storageRatio: [
            {
                required: true,
                validator: validateStorageRatio,
                trigger: 'change',
            },
        ],
        // 储能系统租借单价
        rentalUnitPrice: [
            {
                required: true,
                validator: validateStoragePrice,
                trigger: 'blur',
            },
        ],
        // 升压站点位
        boosterLocation: [
            {
                required: true,
            },
        ],
        // 倾向轮毂高度
        preferHubHeight: [
            {
                required: true,
            },
        ],
        // 支付方式
        rentalPayType: [
            {
                required: true,
            },
        ],
    });
    // 是否显示高级参数
    let showMoreKey = ref(false);
    // 储能系统常量
    const storageOptions = [
        {
            label: '自建',
            value: 1,
        },
        {
            label: '租借',
            value: 2,
        },
        {
            label: '不配置',
            value: 3,
        },
    ];
    // 储能下拉框常量
    const selectStorageRatio = [
        {
            value: 0,
            label: '占10%，1个小时储电',
        },
        {
            value: 1,
            label: '占10%，2个小时储电',
        },
        {
            value: 2,
            label: '占20%，1个小时储电',
        },
        {
            value: 3,
            label: '占20%，2个小时储电',
        },
    ];
    // 场区坐标常量
    const pointOptions = [
        {
            label: '批量导入',
            value: 0,
        },
        {
            label: '手动录入',
            value: 1,
        },
    ];
    // 支付方式
    const payTypeOptions = ref([]);
    // 场区坐标手动录入结果验证，用于控制风机点位上传disabled
    const handleDisabled = ref(false);
    // 场区对应的腾讯地图数据变量
    const tdMapPointData = ref([]);
    // 风机点位常量
    const fanLocationOptions = [
        {
            label: '有',
            value: 1,
        },
        {
            label: '暂无',
            value: 0,
        },
    ];
    // 表单样式变量
    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 14,
        },
    };
    // 自定义验证逻辑
    const customValidate = async () => {
        // 手动触发自己封装组件的验证情况，不通过会显示报错信息
        fanClassifyRef.value.getData();
        uploadImgLandformRef.value.getData();

        if (formState.value.pointType == 1) {
            if (
                !fieldPointRef.value.handlePointRef.getData() ||
                fieldPointRef.value.handlePointRef.getData().length == 0
            ) {
                message.error('请先完整录入场区坐标');
                formRef.value.validateFields(['pointType']);
                return false;
            }
        } else {
            console.log(fieldFanDataStore.pointData);
            if (fieldFanDataStore.pointData.length == 0) {
                // message.error('请先上传场区坐标文件');
                formRef.value.validateFields(['pointType']);
                return false;
            }
        }
        if (formState.value.boosterLocation && fieldFanDataStore.pointData.length >= 1) {
            const result = await checkPointInAreaEvent();
            if (!result) {
                message.error('升压站点位不在场区范围内，请重新录入');
                return false;
            }
        }
        return true;
    };
    const getDistance = (lng1, lat1, lng2, lat2) => {
        let radius = 5;
        if (sessionStorage.getItem('currentProjectCapacity')) {
            if (
                sessionStorage.getItem('currentProjectCapacity') > 50 &&
                sessionStorage.getItem('currentProjectCapacity') <= 100
            ) {
                radius = 10;
            } else if (
                sessionStorage.getItem('currentProjectCapacity') > 100 &&
                sessionStorage.getItem('currentProjectCapacity') <= 200
            ) {
                radius = 15;
            }
        }
        // 角度转弧度
        let a = Math.PI / 180;

        let t1 = Math.sin(lat1 * a) * Math.sin(lat2 * a);
        let t2 = Math.cos(lat1 * a) * Math.cos(lat2 * a);
        let t3 = Math.cos(lng1 * a - lng2 * a);
        let t4 = t2 * t3;
        let t5 = t1 + t4;
        let s = Math.atan(-t5 / Math.sqrt(-t5 * t5 + 1)) + 2 * Math.atan(1);
        s = s * 6378.137; // EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000;
        console.log('两点之间的距离：', s, '半径：', radius);
        return s <= radius;
    };
    const checkPointInAreaEvent = async () => {
        return new Promise(async (resolve, reject) => {
            let pointInArea = true;
            // 如果有升压站数据,需要转坐标
            let formatBoosterLocationData = [];
            if (formState.value.boosterLocation) {
                const res = await formatLatLng(
                    formState.value.boosterStationLocations,
                    'lng',
                    'lat',
                );
                res.length &&
                    res.map((ele) => {
                        const obj = {};
                        obj['x'] = ele['lng'] * 1;
                        obj['y'] = ele['lat'] * 1;
                        formatBoosterLocationData.push(obj);
                    });
            }
            const areaData = {};
            fieldFanDataStore.pointData.length &&
                fieldFanDataStore.pointData.map((ele) => {
                    if (!areaData[ele.regionCode]) {
                        areaData[ele.regionCode] = [];
                    }
                    areaData[ele.regionCode].push([ele.longitude * 1, ele.latitude * 1]);
                });
            // 验证升压站点位是否在场区范围内
            let key = true;
            for (let i = 0; i < formatBoosterLocationData.length; i++) {
                const ele = formatBoosterLocationData[i];
                let subKey = false;
                console.log(Object.values(areaData));
                for (let index = 0; index < Object.values(areaData).length; index++) {
                    const area = Object.values(areaData)[index];
                    if (area.length == 1) {
                        pointInArea = getDistance(area[0], area[1], ele['x'], ele['y']);
                        subKey = subKey || pointInArea;
                        if (index == Object.values(areaData).length - 1) {
                            key = key && subKey;
                        }
                    } else {
                        pointInArea = checkPointInArea([ele['x'], ele['y']], area);
                        subKey = subKey || pointInArea;
                        if (index == Object.values(areaData).length - 1) {
                            key = key && subKey;
                        }
                    }
                }
                if (i == formatBoosterLocationData.length - 1) {
                    resolve(key);
                }
            }
        });
    };
    // 手动提交表单
    const onSubmitForm = useDebounceFn((type) => {
        formRef.value
            .validate()
            .then(() => {
                // 如果没有通过组件自定义验证，直接拦截掉
                customValidate().then((res) => {
                    if (res) {
                        // 表单验证通过,对入参数据进行处理
                        formateSubmitFormData(type);
                    }
                });
            })
            .catch((err) => {
                // 表单验证没通过
                // formRef.value.scrollToField(err.errorFields[0].name);
                console.log('错误', err);
                setTimeout(() => {
                    const errorDiv = document.getElementsByClassName('ant-form-item-explain-error');
                    errorDiv.length &&
                        errorDiv[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 50);
            });
    }, 500);
    // 经纬度格式化处理，大地坐标转十进制经纬度
    const formatLatLng = async (data, lngStr, latStr) => {
        const formatData = [];
        const result = [];
        data.length &&
            data.map((ele) => {
                formatData.push({
                    longitude: ele[lngStr] + '',
                    latitude: ele[latStr] + '',
                });
            });
        console.log({ points: formatData });

        try {
            const res = await coverPoints({ points: formatData });
            if (res.code == 200) {
                if (res.data && JSON.parse(res.data).length) {
                    JSON.parse(res.data).map((item) => {
                        const objItem = {};
                        objItem[lngStr] = item.longitude;
                        objItem[latStr] = item.latitude;
                        result.push(objItem);
                    });
                    return result;
                }
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };
    // 提交时，表单数据格式整理
    const formateSubmitFormData = async (type) => {
        console.log('---------------------');
        // 项目名称格式处理，结尾没有工程、项目两个字要拼接“工程”
        const checkProjectName = (name) => {
            if (name.indexOf('项目') > -1 || name.indexOf('工程') > -1) {
                return name;
            } else {
                return name + '工程';
            }
        };
        // 如果有升压站数据,需要转坐标
        let formatBoosterLocationData = [];
        if (formState.value.boosterLocation) {
            const res = await formatLatLng(formState.value.boosterStationLocations, 'lng', 'lat');
            formatBoosterLocationData = res;
            formatBoosterLocationData.length &&
                formatBoosterLocationData.map((ele, eleIndex) => {
                    ele['lng'] = ele['lng'] * 1;
                    ele['lat'] = ele['lat'] * 1;
                    ele['isBest'] = formState.value.boosterStationLocations[eleIndex]['isBest'];
                });
        }
        // 基础没有高级参数设置情况下
        const params = {
            step: 1, // 当前步骤
            projectAdjustOneStepFlag: true, // 是否是第一步
            projectAdjustFlag: !!route.query.type, // 项目调整标识
            uniqueIdentification: fieldFanDataStore.uniqueIdentificationUUID, // 用于绑定场区和风机文件关联性的唯一标识
            projectName: checkProjectName(formState.value.projectName), // 项目名称
            totalProjectCapacity: formState.value.totalProjectCapacity, // 总规划装机容量
            currentProjectCapacity: formState.value.currentProjectCapacity, // 本期装机容量
            energyStorage: formState.value.energyStorage, // 储能系统
            rentalUnitPrice:
                formState.value.energyStorage == 2 ? formState.value.rentalUnitPrice : 0, // 储能租借单价
            capacityProportion:
                formState.value.energyStorage !== 3 ? formState.value.capacityProportion : 0, // 储能配置比例
            storageHours: formState.value.energyStorage !== 3 ? formState.value.storageHours : 0, // 储能配置电量时长
            isTerrainImage: uploadImgLandformRef.value.getData().length ? 1 : 0, // 是否存在地形现场图片 0不存在 1存在
            terrainImageFile: JSON.stringify(uploadImgLandformRef.value.getData()), // 地形现场图片文件
            isHaveDevicePoints: 0, // 是否存在风机点位文件 0不存在 1存在
            devicePoints: fanPointRef.value
                ? typeof fanPointRef.value.fanPointFileData === 'string'
                    ? fanPointRef.value.fanPointFileData
                    : JSON.stringify(fanPointRef.value.fanPointFileData)
                : '[]', // 风机点位数据
            deviceWindFilePath: fanPointRef.value
                ? typeof fanPointRef.value.fanFile === 'string'
                    ? fanPointRef.value.fanFile
                    : fanPointRef.value.fanFile.length
                      ? JSON.stringify(fanPointRef.value.fanPointFileInfo)
                      : '[]'
                : '[]', // 风机点位文件
            boosterStationLocations: formState.value.boosterLocation
                ? JSON.stringify(formatBoosterLocationData)
                : '[]', // 升压站点位数据
            preferDeviceCodes: '',
            obsPath: obsFilePath.value,
            preferHubHeight: formState.value.preferHubHeight, // 倾向轮毂高度
        };
        // 场区坐标数据格式处理
        if (formState.value.pointType == 0) {
            // 批量导入情况
            params.coordinate = JSON.stringify(fieldPointRef.value.pointFileData);
            params.regionFilePath = JSON.stringify(fieldPointRef.value.pointFileInfo);
        } else {
            // 手动录入情况
            let pointData = [];
            const res = await formatLatLng(
                fieldPointRef.value.handlePointRef.getData(),
                'lng',
                'lat',
            );
            pointData = res;
            const resultPointData = [];
            pointData &&
                pointData.map((ele) => {
                    resultPointData.push({
                        regionCode: 1,
                        longitude: ele.lng,
                        latitude: ele.lat,
                    });
                });
            params.coordinate = JSON.stringify(resultPointData);
            params.regionFilePath = JSON.stringify([]);
        }
        // 倾向使用的风机类型数据格式处理
        if (fanClassifyRef.value.existType * 1 && fanClassifyRef.value.getData().length) {
            let str = '';
            const data = fanClassifyRef.value.getData();
            data.map((ele, eleIndex) => {
                str += ele.classify + ',' + ele.power;
                if (eleIndex != data.length - 1) {
                    str += ';';
                }
            });
            params.preferDeviceCodes = str;
        }
        // 如果是项目调整(或者草稿编辑)的话，需要传项目ID
        if (route.query.type) {
            // 草稿、编辑 + 用于解决用户在第一步点击保存草稿又点击下一步
            params['projectAdjustId'] = sessionStorage.getItem('projectID');
            params['projectId'] = sessionStorage.getItem('projectID');
            params['id'] = sessionStorage.getItem('projectID');
            // 把旧的项目ID存到临时缓存里面
            sessionStorage.setItem('originProjectID', sessionStorage.getItem('projectID'));
        }
        if (formState.value.energyStorage == 2) {
            params['rentalPayType'] = Number(formState.value.rentalPayType); // 支付方式
        }
        // 将整理好的入参，通过接口传给后台
        console.log(params);
        // return false;
        checkFormLoading.value = true;
        try {
            const data =
                route.query.type == 'edit'
                    ? await updateFormData(params)
                    : await saveFormData(params);
            if (data && data.code == 200) {
                checkFormLoading.value = false;
                message.success('保存成功');
                // 临时存储唯一标识和项目id
                sessionStorage.setItem(
                    'uniqueIdentification',
                    fieldFanDataStore.uniqueIdentificationUUID,
                );
                sessionStorage.setItem('projectID', data.data);
                sessionStorage.setItem(
                    'currentProjectCapacity',
                    formState.value.currentProjectCapacity,
                );
                // 接口保存成功
                if (type == 1) {
                    // 保存成草稿，停留在当前页面(添加草稿标识参数)
                    gtag('event', 'save_draft', {
                        user_info_id: sessionStorage.getItem('userId'),
                        project_info_id: sessionStorage.getItem('projectID'),
                    });
                } else {
                    // 点击下一步才会往下走
                    eventBus.emit('validateStep', 2);
                }
            } else {
                if (data && data.code == 400) {
                    Modal.warning({
                        title: '温馨提示',
                        content: h('div', { class: 'modal-content-div' }, [
                            h('p', `${data.msg}`),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `您的客户经理：${customInfos.value?.name} ${customInfos.value?.phone}`,
                            ),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `平台客服：155-6259-8617`,
                            ),
                        ]),
                        okText: '知道了',
                        onOk: () => {},
                    });
                }
                checkFormLoading.value = false;
            }
        } catch (error) {
            checkFormLoading.value = false;
        }
    };
    // 升压站站点
    const selectbestRatio = [
        {
            value: 1,
            label: '最优',
        },
        {
            value: 0,
            label: '非最优',
        },
    ];
    // 升压站坐标点数据变量
    const handlePointCount = ref(1);
    // 切换拐点类型事const
    const changePointCount = (count) => {
        handlePointCount.value = count;
        formState.value.boosterStationLocations = [{}];
        for (let i = 1; i < count; i++) {
            formState.value.boosterStationLocations.push({});
        }
    };
    // 验证升压站坐标点数据(为了渲染给地图)
    const checkStationLocations = async () => {
        const originData = [];
        if (formState.value.boosterStationLocations.length) {
            formState.value.boosterStationLocations.forEach((item) => {
                if (item.lng && item.lat) {
                    originData.push({
                        lng: item.lng * 1,
                        lat: item.lat * 1,
                    });
                }
            });
            if (originData.length) {
                const res = await formatLatLng(originData, 'lng', 'lat');
                const resultData = [];
                if (res.length) {
                    res.map((ele) => {
                        resultData.push({
                            longitude: ele.lng * 1,
                            latitude: ele.lat * 1,
                        });
                    });
                    eventBus.emit('uploadBooster', resultData);
                }
            } else {
                eventBus.emit('uploadBooster', []);
            }
        } else {
            eventBus.emit('uploadBooster', []);
        }
    };
    // 切换配置容量占本期比例事件
    const onChangeSelectStorageRatio = () => {
        switch (formState.value.select) {
            case 0:
                formState.value.capacityProportion = 10;
                formState.value.storageHours = 1;
                break;
            case 1:
                formState.value.capacityProportion = 10;
                formState.value.storageHours = 2;
                break;
            case 2:
                formState.value.capacityProportion = 20;
                formState.value.storageHours = 1;
                break;
            case 3:
                formState.value.capacityProportion = 20;
                formState.value.storageHours = 2;
                break;
            default:
                break;
        }
    };
    // 手动触发表单场区验证\风机验证
    const checkFormValidateFields = () => {
        console.log('表单场区验证');
        formRef.value.validateFields(['pointType']);
    };
    const checkFormValidateFieldsFan = () => {
        console.log('表单风机验证');
        formRef.value.validateFields(['fanLocation']);
    };
    // 清除风机表单验证
    const clearValidateFan = () => {
        formRef.value.clearValidate('fanLocation');
    };
    // 根据项目ID进行回显
    const getProjectInfoByID = async (projectID) => {
        showMoreKey.value = true;
        const data = await selectProjectInfoById({ id: projectID });
        if (data && data.code == 200) {
            const infos = data.data;
            sessionStorage.setItem('currentProjectCapacity', infos.currentProjectCapacity);
            formState.value = Object.assign(
                formState.value,
                {
                    projectName: infos.projectName,
                    totalProjectCapacity: infos.totalProjectCapacity, // 总装机容量
                    currentProjectCapacity: infos.currentProjectCapacity, // 本期装机容量
                    energyStorage: infos.energyStorage,
                    rentalUnitPrice: infos.rentalUnitPrice ? infos.rentalUnitPrice : '', // 配置容量����单价
                    capacityProportion: infos.capacityProportion,
                    storageHours: infos.storageHours,
                    isTerrainImage: infos.isTerrainImage, // 是否存在地形现场图片 0不存在 1存在
                    fanLocation: JSON.parse(infos.devicePoints).length ? 1 : 0, // 风机点位单选
                    boosterLocation: JSON.parse(infos.boosterStationLocations).length ? 1 : 0, // 升压站点位单选
                    boosterStationLocations: JSON.parse(infos.boosterStationLocations), // 升压站点位数据
                    preferHubHeight: infos.preferHubHeight || 120, // 轮毂高度
                    rentalPayType: infos.rentalPayType ? `${infos.rentalPayType}` : null, // 支付方式
                },
                {},
            );

            // obs文件上传路径处理（调整步骤调整第四级路径）
            if (infos.obsPath) {
                const pathArr = infos.obsPath.split('/');
                pathArr[3] = getProjectRandom();
                let str = '';
                pathArr.map((ele, eleIndex) => {
                    str += ele;
                    if (eleIndex != pathArr.length - 1) {
                        str += '/';
                    }
                });
                obsFilePath.value = str;
                sessionStorage.setItem('obsFilePath', obsFilePath.value);
            }

            // 配置容量占本期比例下拉框回显
            if (infos.storageHours && infos.capacityProportion) {
                const energyStorageValue = infos.storageHours + ',' + infos.capacityProportion;
                switch (energyStorageValue) {
                    case '1,10':
                        formState.value.select = 0;
                        break;
                    case '2,10':
                        formState.value.select = 1;
                        break;
                    case '1,20':
                        formState.value.select = 2;
                        break;
                    case '2,20':
                        formState.value.select = 3;
                        break;
                    default:
                        break;
                }
            } else {
                formState.value.select = null;
            }

            // 场区坐标点存放到pinia
            fieldFanDataStore.pointData = infos.coordinate ? JSON.parse(infos.coordinate) : [];
            console.log('fieldFanDataStore.pointData', fieldFanDataStore.pointData);
            // 往场区坐标组件里面塞数据
            if (fieldPointRef.value) {
                fieldPointRef.value.pointFileData = infos.coordinate
                    ? JSON.parse(infos.coordinate)
                    : [];
                fieldPointRef.value.pointFileList = infos.coordinate
                    ? [
                          {
                              filePath: '',
                              suffix: '',
                              fileName: '',
                          },
                      ]
                    : [];
            }
            // 风机坐标地图回显
            if (JSON.parse(infos.devicePoints).length) {
                eventBus.emit('uploadFan', JSON.parse(infos.devicePoints));
                fanPointRef.value &&
                    (fanPointRef.value.fanPointFileData = JSON.parse(infos.devicePoints));
            } else {
                eventBus.emit('uploadFan', []);
            }

            // 推荐使用的风机类型数据回显
            formState.value.preferDeviceCodes = infos.preferDeviceCodes;
            if (infos.preferDeviceCodes) {
                const data = infos.preferDeviceCodes.split(';');
                const result = [];
                data.map((ele) => {
                    result.push({
                        classify: ele.split(',')[0],
                        power: ele.split(',')[1],
                    });
                });
                fanClassifyRef.value.updatePointData(result);
            }

            // 升压站数据回显
            if (JSON.parse(infos.boosterStationLocations).length > 0) {
                handlePointCount.value = JSON.parse(infos.boosterStationLocations).length;
                const resultData = [];
                JSON.parse(infos.boosterStationLocations).map((ele, eleIndex) => {
                    resultData.push({
                        longitude: ele.lng * 1,
                        latitude: ele.lat * 1,
                    });
                });
                eventBus.emit('uploadBooster', resultData);
            } else {
                formState.value.boosterStationLocations = [{}];
            }
            // 拿到详情传过来的文件数组，对应模块文件数据回填
            nextTick(() => {
                if (infos.projectFileList) {
                    // 地形现场照片回显
                    const imageData = infos.projectFileList.filter((ele) => {
                        return ele.fileType == 1;
                    });
                    if (imageData.length > 0) {
                        uploadImgLandformRef.value.updateFileData(imageData);
                    }

                    // 场区文件回显
                    const pointFile = infos.projectFileList.filter((ele) => {
                        return ele.fileType == 10;
                    });
                    if (pointFile.length > 0) {
                        pointFile.forEach((element) => {
                            element.filePath = decodeURIComponent(element.filePath);
                            element.suffix = element.fileSuffix
                                ? element.fileSuffix
                                : element.fileName.split('.')[1];
                        });
                        fieldFanDataStore.pointFile = pointFile;
                        nextTick(() => {
                            fieldPointRef.value && (fieldPointRef.value.pointFileInfo = pointFile); // 用于组件文件信息提交
                        });
                    }

                    // 天地图场区数据回显
                    let pointsFormat = [];
                    if (infos.coordinate && pointFile.length > 0) {
                        formState.value.pointType = 0;
                        const pointDataObj = {};
                        const pointFileOriginData = JSON.parse(infos.coordinate);
                        pointFileOriginData.map((ele) => {
                            if (!pointDataObj[ele.regionCode]) {
                                pointDataObj[ele.regionCode] = [];
                            }
                            pointDataObj[ele.regionCode].push([ele.latitude, ele.longitude]);
                        });
                        tdMapPointData.value = Object.values(pointDataObj);
                        // fanPointFileData.value = JSON.parse(infos.devicePoints).length
                        //     ? JSON.parse(infos.devicePoints)
                        //     : [];
                        eventBus.emit('uploadPoint', Object.values(pointDataObj));
                    } else if (infos.coordinate && pointFile.length == 0) {
                        formState.value.pointType = 1;
                        const formateResult = [];
                        JSON.parse(infos.coordinate).map((ele) => {
                            formateResult.push({
                                lng: ele.longitude,
                                lat: ele.latitude,
                            });
                            pointsFormat.push([ele.latitude, ele.longitude]);
                        });
                        const pointDataObj = {};
                        const pointFileOriginData = JSON.parse(infos.coordinate);
                        pointFileOriginData.map((ele) => {
                            if (!pointDataObj[ele.regionCode]) {
                                pointDataObj[ele.regionCode] = [];
                            }
                            pointDataObj[ele.regionCode].push([ele.latitude, ele.longitude]);
                        });
                        tdMapPointData.value = Object.values(pointDataObj);
                        // fanPointFileData.value = JSON.parse(infos.devicePoints).length
                        //     ? JSON.parse(infos.devicePoints)
                        //     : [];
                        eventBus.emit('uploadPoint', Object.values(pointDataObj));
                        nextTick(() => {
                            fieldPointRef.value.handlePointRef.updatePointData(formateResult);
                            // handlePointRef.value.updatePointData(formateResult);
                        });
                    }
                    pointFile.length > 0 &&
                        fieldPointRef.value &&
                        fieldPointRef.value.pointLocationRef.updateFileData(pointFile);

                    // 风机文件回显
                    const fanFile = infos.projectFileList.filter((ele) => {
                        return ele.fileType == 11;
                    });
                    formState.value.fanLocation = fanFile.length > 0 ? 1 : 0;
                    if (fanFile.length > 0 && fanPointRef.value) {
                        fanPointRef.value.fanFile = fanFile;
                        fanPointRef.value.fanLocationRef.updateFileData(fanFile);
                        fanPointRef.value.fanPointFileInfo = fanFile;
                        fieldFanDataStore.fanFile = fanFile;
                        fanPointRef.value.fanPointFileData = infos.devicePoints;
                    }
                    reloadMapData(infos);
                }
            });
        }
    };
    // 根据项目回显数据进行天地图坐标渲染(为了显示第二步中坐标数据)
    const reloadMapData = async (infoDatas) => {
        // 变电站位置坐标回显
        if (
            infoDatas.accessSubstationPoints &&
            JSON.parse(infoDatas.accessSubstationPoints).length
        ) {
            const res = JSON.parse(infoDatas.accessSubstationPoints);
            eventBus.emit('uploadSubStation', [{ longitude: res[0], latitude: res[1] }]);
        }
        // 测风塔坐标回显
        if (infoDatas.cftPointList && JSON.parse(infoDatas.cftPointList).length) {
            eventBus.emit('uploadTower', JSON.parse(infoDatas.cftPointList));
        }
        // 避让区域回显
        if (infoDatas.avoidAreaFileGroupList) {
            for (let index = 0; index < infoDatas.avoidAreaFileGroupList.length; index++) {
                const element = infoDatas.avoidAreaFileGroupList[index];
                const subIndex = element.files.findIndex((ele) => ele.fileSuffix == 'shp');
                if (subIndex > -1) {
                    // const res = await obsClient.getFileUrl(element.files[subIndex]['filePath']);
                    try {
                        await obsClient.copyFile(
                            'shpCopy/' + element.files[subIndex]['filePath'],
                            obsClientInfo.bucketname + '/' + element.files[subIndex]['filePath'],
                        );
                        console.log(element.files[subIndex]['filePath']);
                        // const geojson = await shp(
                        //     'https://' +
                        //         obsClientInfo.publicBucketname +
                        //         '.obs.cn-north-4.myhuaweicloud.com/shpCopy/' +
                        //         element.files[subIndex]['filePath'],
                        // );
                        shp(
                            'https://' +
                                obsClientInfo.publicBucketname +
                                '.obs.cn-north-4.myhuaweicloud.com/shpCopy/' +
                                element.files[subIndex]['filePath'],
                        ).then((res) => {
                            console.log(res);
                        });
                        // eventBus.emit('uploadAvoid', {
                        //     data: geojson,
                        //     name: 'name',
                        //     type: 'AreaOfAvoidance',
                        // });
                        // 经纬度渲染完需要删除copy过的文件
                        const rea = await obsClient.delFile(
                            obsClientInfo.publicBucketname,
                            'shpCopy/' + element.files[subIndex]['filePath'],
                        );
                    } catch (error) {}
                }
            }
        }
        // 可布机区域回显
        if (infoDatas.canDeployableAreaGroupList) {
            for (let index = 0; index < infoDatas.canDeployableAreaGroupList.length; index++) {
                const element = infoDatas.canDeployableAreaGroupList[index];
                const subIndex = element.files.findIndex((ele) => ele.fileSuffix == 'shp');
                if (subIndex > -1) {
                    console.log(index);
                    const objectKey = element.files[subIndex]['filePath'].split('?')[0];
                    try {
                        const rew = await obsClient.copyFile(
                            'shpCopy/' + objectKey,
                            obsClientInfo.bucketname + '/' + objectKey,
                        );
                        const geojson = await shp(
                            'https://' +
                                obsClientInfo.publicBucketname +
                                '.obs.cn-north-4.myhuaweicloud.com/shpCopy/' +
                                objectKey,
                        );
                        eventBus.emit('uploadAvoid', {
                            data: geojson,
                            name: 'name',
                            type: 'MachineArea',
                        });
                        const rea = await obsClient.delFile(
                            obsClientInfo.publicBucketname,
                            'shpCopy/' + objectKey,
                        );
                    } catch (error) {}
                }
            }
        }
    };
    const getPayDictionary = async () => {
        try {
            let params = {
                dictType: 'rental_pay_type',
            };
            let res = await getDictionary(params);
            if (res.code === 200 && res.data && res.data.length) {
                payTypeOptions.value = res.data;
            }
        } catch (error) {}
    };
    watch([() => formState.value.fanLocation], () => {
        if (formState.value.fanLocation == 0) {
            // 需要清除风机数据
            eventBus.emit('clearFanData');
        }
    });
    watch(() => formState.value.boosterLocation, () => {
        if (formState.value.boosterLocation == 0) {
            eventBus.emit('uploadBooster', []);
        } else {
            nextTick(() => {
                checkStationLocations();
            });
        }
    })
</script>
<style scoped lang="scss">
    @import './FirstStep.scss';
</style>
<style>
    .modal-content-div p:first-child {
        margin: 8px 0 12px 0;
    }
    .modal-content-desc {
        margin-bottom: 5px;
        font-size: 12px;
        color: #7c8294;
        word-break: break-all;
    }
</style>
