import {
    ref
} from 'vue';
import {
    obsClientInfo
} from '@/assets/js/static';
import ObsClient from 'esdk-obs-browserjs';
import {
    getCertificate
} from '@/api/modules/base.js';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
export function useObsUpload() {
    // const route = useRoute();
    let obsClient = null;
    // const bucketname = 'test-dt';
    const bucketname = obsClientInfo.bucketname;
    const objectname = ref('');
    const urlPath = ref('');
    const processSize = ref(0);
    const UploadId = ref('');
    const isEnd = ref(false);
    let cp = ref();
    let hook = ref();

    const getObsClient = async () => {
        await getCertificate().then((res) => {
            console.log(res, '-------------');
            if (res && res.data) {
                // obsClient = new ObsClient({
                //     access_key_id: res.data.access, // ak
                //     secret_access_key: res.data.secret, // sk
                //     security_token: res.data.securityToken,
                //     server: obsClientInfo.endPoint, // endPoint
                // });
                const infos = {
                    ak: res.data.access,
                    sk: res.data.secret,
                    securityToken: res.data.securityToken,
                    endPoint: obsClientInfo.endPoint,
                    expiresAt: res.data.expiresAt,
                }
                sessionStorage.setItem('obsInfo', JSON.stringify(infos));
                obsClient = new ObsClient({
                    access_key_id: infos['ak'], // ak
                    secret_access_key: infos['sk'], // sk
                    security_token: infos['securityToken'],
                    server: infos['endPoint'], // endPoint
                });
                // console.log(obsClientInfo);
            }

        })
    };
    const checkObsClient = async () => {
        if (!sessionStorage.getItem('obsInfo')) {
           await getObsClient();
        } else {
            const infos = JSON.parse(sessionStorage.getItem('obsInfo'));
            if (new Date().getTime() < infos.expiresAt) {
                // 临时token还在有效期内
                obsClient = new ObsClient({
                    access_key_id: infos['ak'], // ak
                    secret_access_key: infos['sk'], // sk
                    security_token: infos['securityToken'],
                    server: infos['endPoint'], // endPoint
                });
            } else {
              await  getObsClient();
            }
        }
    }
    
    const uploadFile = async (files, storePath) => {
        await checkObsClient();
        processSize.value = 0;
        const file = files;
        objectname.value = (storePath ? storePath : 'whln/fd/pro-xxxx/od/') + file.name;
        // 判断文件的大小选择合适的上传方式
        if (file.size < 1024 * 1024 * 5) {
            console.log('小于5MB，使用简单上传', obsClient);
            return new Promise((resolve, reject) => {
                // 小于5MB，使用简单上传
                obsClient.putObject({
                        Bucket: bucketname,
                        Key: objectname.value, // 路径+文件名
                        SourceFile: file,
                        ProgressCallback: function (transferredAmount, totalAmount, totalSeconds) {
                            // processSize.value =Math.floor((transferredAmount * 100.0) / totalAmount);
                            processSize.value = (transferredAmount * 100.0) / totalAmount;
                            console.log('transferredAmount', processSize.value);
                            // console.log((transferredAmount * 1.0) / totalSeconds / 1024);
                            // console.log((transferredAmount * 100.0) / totalAmount);
                        },
                    },
                    (error, result) => {
                        if (error) {
                            return new Promise(async (resolve, reject) => {
                                if (error.message.includes('timeout')) {
                                    // 处理超时错误
                                    message.error('上传超时，请稍后重试！');
                                    resolve({msg:'上传超时，请稍后重试！', obsPath: ''});
                                } else if (error.message.includes('network')) {
                                    // 处理网络错误
                                    message.error('网络错误，请检查您的网络连接！');
                                    resolve({msg:'网络错误，请检查您的网络连接！', obsPath: ''});
                                } else {
                                    // 处理其他错误
                                    message.error('上传失败，请稍后重试！');
                                    resolve({msg:'上传失败，请稍后重试！', obsPath: ''});
                                }
                            });
            
                        }
                        resolve(result);
                    },
                );
            }).then(async (res) => {
                console.log('res', res);
                return new Promise(async (resolve, reject) => {
                    await getFileUrl();
                    let file = res;
                    // file.obsPath = urlPath.value;
                    // 不返回完整路径方案
                    file.obsPath = urlPath.value.split(':443/')[1].split('?')[0];
                    resolve(file);
                });
            }).catch((error) => {
               
            });
        } else {
            return await uploadToObs(file);
        }
    };
    // 断点续传
    const uploadToObs = async (file) => {
        isEnd.value = false;
        // let cp;
        // let hook;
        // 断点续传
        return new Promise((resolve, reject) => {
            obsClient.uploadFile({
                    Bucket: bucketname,
                    Key: objectname.value, // 路径+文件名
                    SourceFile: file,
                    PartSize: 5 * 1024 * 1024,
                    ProgressCallback: function (transferredAmount, totalAmount, totalSeconds) {
                        // processSize.value =Math.floor((transferredAmount * 100.0) / totalAmount);
                        processSize.value = (transferredAmount * 100.0) / totalAmount;
                        // console.log((transferredAmount * 1.0) / totalSeconds / 1024);
                        // console.log((transferredAmount * 100.0) / totalAmount);
                    },
                    EventCallback: function (eventType, eventParam, eventResult) {
                        // 处理事件响应
                        UploadId.value = eventParam.uploadId;
                        objectname.value = eventParam.key;
                    },
                    ResumeCallback: function (resumeHook, uploadCheckpoint) {
                        // 获取取消断点续传上传任务控制参数
                        hook.value = resumeHook;
                        // 记录断点
                        cp.value = uploadCheckpoint;
                    },
                },
                function (err, result) {
                    const resultInfo = uploadEvent(err, result)
                    resultInfo ? resolve(resultInfo) : reject(err);
                },
            );

        }).then((res) => {
            return new Promise(async (resolve, reject) => {
                await getFileUrl();
                let file = res;
                // file.obsPath = urlPath.value;
                // 不返回完整路径方案
                file.obsPath = urlPath.value.split(':443/')[1].split('?')[0];
                resolve(file);
            });
        }).catch((error) => {
            console.log(error,'err', error.message);
            return new Promise(async (resolve, reject) => {
                if (error.message.includes('timeout')) {
                    // 处理超时错误
                    message.error('上传超时，请稍后重试！');
                    resolve({msg:'上传超时，请稍后重试！', obsPath: ''});
                } else if (error.message.includes('network')) {
                    // 处理网络错误
                    message.error('网络错误，请检查您的网络连接！');
                    resolve({msg:'网络错误，请检查您的网络连接！', obsPath: ''});
                } else {
                    // 处理其他错误
                    message.error('上传失败，请稍后重试！');
                    resolve({msg:'上传失败，请稍后重试！', obsPath: ''});
                }
            });
           
        })
    };
    const uploadEvent = function (err, result) {
        console.log('Error---->' + err);
        // new Promise((resolve, reject) => {
        if (err) {
            if (isEnd.value) {
                processSize.value = 0;
                UploadId.value && obsClient.abortMultipartUpload({
                    Bucket: bucketname,
                    Key: objectname.value,
                    UploadId: UploadId.value,
                }, function (err, result) {
                    if (err) {
                        console.error('Error-->' + err);
                    } else {
                        console.log('Status-->' + result.CommonMsg.Status);
                    }
                })
                return false
            }
            obsClient.uploadFile({
                    UploadCheckpoint: cp.value,
                    ProgressCallback: function (
                        transferredAmount,
                        totalAmount,
                        totalSeconds,
                    ) {
                        // console.log((transferredAmount * 100.0) / totalAmount);
                        processSize.value = (transferredAmount * 100.0) / totalAmount;
                        // processSize.value = Math.floor((transferredAmount * 100.0) / totalAmount);
                    },
                    EventCallback: function (eventType, eventParam, eventResult) {
                        // 处理事件响应
                    },
                },
                function (err, result) {
                    if (err) {
                        console.error('Error-->' + err);
                    } else {
                        if (result.CommonMsg.Status < 300) {
                            resolve(result);
                            console.log(
                                'RequestId-->' + result.InterfaceResult.RequestId,
                            );
                            console.log('Bucket-->' + result.InterfaceResult.Bucket);
                            console.log('Key-->' + result.InterfaceResult.Key);
                            console.log(
                                'Location-->' + result.InterfaceResult.Location,
                            );
                        } else {
                            console.log('Code-->' + result.CommonMsg.Code);
                            console.log('Message-->' + result.CommonMsg.Message);
                        }
                    }
                },
            );
        } else {
            if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                console.log(result);
                console.log('RequestId-->' + result.InterfaceResult.RequestId);
                // resolve(result);
                return result;
            }
        }
        // })
    }
    // 获取文件的url
    const getFileUrl = async(filePath) => {
        await checkObsClient();
        return new Promise((resolve, reject) => {
            obsClient.getObject({
                    Bucket: bucketname,
                    Key: filePath ? decodeURI(filePath) : objectname.value, // 路径+文件名
                    SaveByType: 'file',
                },
                function (err, result) {
                    if (err) {
                        console.error('Error-->' + err);
                    } else {
                        console.log('Status-->', result.InterfaceResult);
                        if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                            // 获取下载对象的路径
                            console.log(result.InterfaceResult.Content.SignedUrl);
                            urlPath.value = result.InterfaceResult.Content.SignedUrl;
                            resolve(result.InterfaceResult.Content.SignedUrl)
                        } else {
                            urlPath.value = '';
                        }
                    }
                },
            );
        }).then((res) => {
            console.log('注意出结果',res);
            return decodeURI(res); 
        })
    };
    // 复制对象
    const copyFile = async(filePath, copySourcePath) => {
        await checkObsClient();
        return new Promise((resolve, reject) => {
            obsClient.copyObject({
                Bucket: obsClientInfo.publicBucketname,
                Key: decodeURI(filePath) , // 路径+文件名
                CopySource: decodeURI(copySourcePath),
            },
                function (err, result) {
                    if (err) {
                        console.error('Error-->' + err);
                    } else {
                        // console.log(result);
                        // console.log('Status-->', result.InterfaceResult);
                        if (result.CommonMsg.Status < 300) {
                            // 复制对象成功
                            resolve();
                        } else {
                        }
                    }
                },
            );
        }).then((res) => {
            return ''; 
        })
    }
    // 删除对象
    const delFile = async(bucketname, filePath) => {
        await checkObsClient();
        return new Promise((resolve, reject) => {
            obsClient.deleteObject({
                Bucket: bucketname,
                Key: decodeURI(filePath), // 路径+文件名
            },
                function (err, result) {
                    if (err) {
                        console.error('Error-->' + err);
                    } else {
                        // console.log(result);
                        // console.log('Status-->', result.InterfaceResult);
                        if (result.CommonMsg.Status < 300) {
                            // 复制对象成功
                            resolve();
                        } else {
                        }
                    }
                },
            );
        }).then((res) => {
            return ''; 
        })
    }
    // 手动取消上传任务
    const abortUpload = () => {
        isEnd.value = true;
        hook.value?.cancel();
    }
    return {
        uploadFile,
        processSize,
        getFileUrl,
        urlPath,
        abortUpload,
        obsClient,
        copyFile,
        delFile,
    };
}