<template>
    <!-- 批量导入逻辑 -->
    <div class="left-indent" v-show="props.pointType == 0" v-loading="[pointAreaLoading, true]">
        <p class="line-download">
            <span>点击这里先</span>
            <a href="javascript:;" @click.stop="downLoadFile(templatePath['point'])"
                >下载导入模板.xlsx</a
            >
        </p>
        <Upload
            ref="pointLocationRef"
            :fileTypes="'xlsx'"
            v-model:defaultFiles="pointFile"
            :fileSizeMessage="'每个文件最大限制为5M'"
            :fileTypeMessage="'请上传.xlsx格式模板数据文件'"
            :isImmediatelyUpload="false"
            @upFileList="upPointFileList"
        >
            <template v-slot:tip>
                <span class="illustrate">上传已填写的模板数据</span>
            </template>
        </Upload>
        <template v-if="pointErrorInfos.length">
            <p class="error-info-title">
                <InfoCircleOutlined style="color: #fe830f" />
                导入数据存在以下异常，请修正后重新上传
            </p>
            <ul class="error-info-list">
                <template v-for="(item, index) in pointErrorInfos" :key="index">
                    <li v-if="item">
                        {{ item }}
                    </li>
                </template>
            </ul>
        </template>
    </div>
    <!-- 手动录入逻辑 -->
    <div class="left-indent" v-show="props.pointType == 1">
        <handlePoint
            :pointData="pointData || []"
            ref="handlePointRef"
            @updatePointData="updatePointData"
        ></handlePoint>
        <p class="form-item-illustrate">
            <span>说明：</span>
            <span>
                手动输入暂不支持4个点以上点位数据，超过4个点请使用批量导入功能。请按照封闭区域点位顺序添加。经纬度需是基于CGCS
                2000坐标系
            </span>
        </p>
    </div>
</template>
<style scoped lang="scss">
    @import './fieldPoint.scss';
</style>
<script setup>
    import { ref, watch, nextTick } from 'vue';
    import { templatePath } from '@/assets/js/static.js';
    import Upload from '@/components/upload/Upload.vue';
    import handlePoint from '../handlePoint/handlePoint.vue';
    import eventBus from '@/utils/eventBus';
    import { useObsUpload } from '@/components/obsClient/obsUpload';
    import { InfoCircleOutlined } from '@ant-design/icons-vue';
    import {
        uniqueIdentification, // 生成唯一标识
        checkPointInArea, // 检测风机点位文件
        checkAreaPointData, // 检测场区坐标文件上传
        saveFormData, // 保存更新提交表单（新增）
        updateFormData, // 调整保存或者更新表单（调整）
        selectProjectInfoById, //根据项目ID进行信息回显
    } from '@/api/modules/firstStep';
    import { coverPoints } from '@/api/modules/base.js';
    import usefieldFanDataStore from '@/pinia/modules/fieldFanData';
    let fieldFanDataStore = usefieldFanDataStore();
    const handlePointRef = ref(null);
    const pointLocationRef = ref(null);
    const obsUpload = useObsUpload('fd/');
    const pointAreaLoading = ref(false);
    const emits = defineEmits(['checkFormValidateFields']);
    const props = defineProps({
        pointType: {
            type: Number,
            default: 1,
        },
    });
    // 拐点文件
    const pointFile = ref([]);
    const pointFileList = ref([]);
    const pointFileData = ref([]);
    const pointFileInfo = ref([]);
    // 拐点数据
    const pointData = ref([]);
    // 传给天地图格式的拐点数据
    const tdMapPointData = ref([]);
    // 拐点错误信息
    const pointErrorInfos = ref([]);
    // 下载模板文件
    const downLoadFile = (path) => {
        window.open(path);
    };
    // 经纬度格式化处理，大地坐标转十进制经纬度
    const formatLatLng = async (data, lngStr, latStr) => {
        const formatData = [];
        const result = [];
        data.length &&
            data.map((ele) => {
                formatData.push({
                    longitude: ele[lngStr] + '',
                    latitude: ele[latStr] + '',
                });
            });
        console.log({ points: formatData });
        try {
            const res = await coverPoints({ points: formatData });
            console.log(res);
            if (res.code == 200) {
                if (res.data && JSON.parse(res.data).length) {
                    JSON.parse(res.data).map((item) => {
                        const objItem = {};
                        objItem[lngStr] = item.longitude;
                        objItem[latStr] = item.latitude;
                        result.push(objItem);
                    });
                    console.log(result, '转完的结果');
                    return result;
                }
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };
    // 场区坐标文件上传
    const upPointFileList = async (file) => {
        console.log(file, 'files-------------');
        // pointAreaLoading.value = true;
        // 腾讯地图数据重置
        tdMapPointData.value = [];
        // 场区坐标文件重置
        pointFileList.value = [];
        // 场区坐标文件解析数据重置
        pointFileData.value = [];
        // 场区坐标文件信息重置
        pointFileInfo.value = [];
        pointFile.value = [];
        fieldFanDataStore.pointFile = [];
        eventBus.emit('clearFanData');
        // fanPointFileData.value = []; // 风机点位数据置空 (通知风机组件数据清空)
        pointErrorInfos.value = []; // 风机点位错误信息重置
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uniqueIdentification', fieldFanDataStore.uniqueIdentificationUUID);
        try {
            const data = await checkAreaPointData(formData);
            if (data && (data.code === 200 || data.code === 10030006)) {
                pointFileList.value = [file];
                pointErrorInfos.value = [];
                pointAreaLoading.value = false;
                // 天地图经纬度数据格式处理
                if (data.data && data.data.length) {
                    pointFileData.value = data.data;
                    const pointDataObj = {};
                    data.data.map((ele) => {
                        if (!pointDataObj[ele.regionCode]) {
                            pointDataObj[ele.regionCode] = [];
                        }
                        pointDataObj[ele.regionCode].push([ele.latitude, ele.longitude]);
                    });
                    fieldFanDataStore.pointData = data.data;

                    tdMapPointData.value = Object.values(pointDataObj);
                    pointFile.value.push(file);
                    fieldFanDataStore.pointFile.push(file);
                    // 上传至华为云获取文件线上路径
                    let uploadResult = await obsUpload.uploadFile(
                        file,
                        sessionStorage.getItem('obsFilePath'),
                    );
                    console.log(uploadResult);
                    console.log('----------------');

                    pointFileInfo.value.push({
                        fileName: file.name,
                        filePath: uploadResult.obsPath,
                        suffix: '.' + file.name.split('.')[file.name.split('.').length - 1],
                    });
                    emits('checkFormValidateFields');
                    eventBus.emit('uploadPoint', tdMapPointData.value);
                }
                // 存在校验信息情况下
                if (data.msg) {
                    pointErrorInfos.value = data.msg.split('/n');
                }
            } else {
                pointAreaLoading.value = false;
                // 接口失败情况下
                pointLocationRef.value.deleteItem();
                // 存在校验信息情况下
                if (data.msg) {
                    pointErrorInfos.value = data.msg.split('/n');
                    console.log(pointErrorInfos.value);
                }
            }
        } catch (error) {
            pointAreaLoading.value = false;
        }
        emits('checkFormValidateFields');
        // formRef.value.validateFields(['pointType']);
        // （手动验证表单场区）
    };
    // 手动输入
    const updatePointData = async (data) => {
        console.log('执行', data);
        // (通知表单验证)
        // emits('checkFormValidateFields');
        // formRef.value.validateFields(['pointType']);
        // 数据长度为真，证明场区坐标手动录入完整，用户可以上传风机点位文件
        // （控制风机文件上传组件是否可以上传）
        // handleDisabled.value = data && data.length > 0;
        if (data && data.length) {
            console.log(data);
            let formatData = [];
            const resultData = [];
            fieldFanDataStore.pointData = [];
            const res = await formatLatLng(data, 'lng', 'lat');
            formatData = res;
            formatData.length &&
                formatData.map((ele) => {
                    resultData.push([ele.lat * 1, ele.lng * 1]);
                    fieldFanDataStore.pointData.push({
                        regionCode: 1,
                        longitude: ele.lng,
                        latitude: ele.lat,
                    })
                });
            console.log(resultData, 'resultData');
            eventBus.emit('uploadPoint', [resultData]);
            eventBus.emit('fanDisabled', false);
        } else {
            eventBus.emit('uploadPoint', []);
            eventBus.emit('fanDisabled', true);
        }
    };
    watch(
        () => pointFile.value.length,
        () => {
            console.log(props.pointType);
            if (!pointFile.value.length) {
                
                fieldFanDataStore.pointFile = [];
                fieldFanDataStore.pointData = [];
                eventBus.emit('uploadPoint', []);
                eventBus.emit('uploadFan', []);
                // (通知风机组件清除表单验证，eventBus风机传空数组，数据清空)
            }
            nextTick(() => {
                console.log(props.pointType);
                !props.pointType && eventBus.emit('fanDisabled', !pointFile.value.length);
            });
        },
        {
            immediate: true,
            deep: true,
        },
    );
    watch(
        () => props.pointType,
        () => {
            emits('checkFormValidateFields');
            nextTick(() => {
                if (!props.pointType) {
                    eventBus.emit('fanDisabled', !fieldFanDataStore.pointFile.length)
                } else {
                    eventBus.emit('fanDisabled', !handlePointRef.value.getData() || !handlePointRef.value.getData().length)
                }
            });
        },
        {
            deep: true,
        }
    );
    defineExpose({
        pointErrorInfos, // 拐点错误信息
        pointFileData,
        pointFileInfo,
        pointFileList,
        handlePointRef,
        pointLocationRef
    });
</script>
